<template>
    <!--begin::Card-->
    <div class="card card-custom card-stretch">
        <!--begin::Header-->
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">
                    Parola si permisiuni
                </h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1"
                    >Modifica modifica parola si permisiunile</span
                >
            </div>
            <div class="card-toolbar">
                <button class="btn btn-success mr-2" @click="onSubmit">Salveaza</button>
                <button type="reset" class="btn btn-secondary" @click="onReset">
                    Reset
                </button>
            </div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form">
            <!--begin::Body-->
            <div class="card-body">
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-alert"
                        >Paola noua</label
                    >
                    <div class="col-lg-9 col-xl-6">
                        <input
                            type="password"
                            class="form-control form-control-lg form-control-solid"
                            v-model="form.password"
                            placeholder="New password"
                        />
                        <span class="form-text text-muted"
                            >Trebuie sa contina minim 8 caractere</span
                        >
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-alert"
                        >Verificare parola</label
                    >
                    <div class="col-lg-9 col-xl-6">
                        <input
                            type="password"
                            class="form-control form-control-lg form-control-solid"
                            v-model="form.confirmPassword"
                            placeholder="Verify password"
                        />
                        <span class="form-text text-muted"
                            >Trebuie sa fie identica cu parola</span
                        >
                    </div>
                </div>
                <div class="separator separator-dashed my-5 mt-10"></div>
                <div class="form-group row justify-content-end">
                    <b-button class="btn btn-danger mr-2" @click="confirm = true">
                        Sterge Utilizator</b-button
                    >
                    <v-dialog v-model="confirm" max-width="400">
                        <v-card>
                            <v-card-title class="headline" style="word-break: break-word"
                                >Sunteti sigur ca doriti sa stergeti utilizatorul:
                                <span class="text-danger">
                                    {{ currentUser.firstName }}
                                    {{ currentUser.lastName }}
                                </span>
                            </v-card-title>
                            <v-card-text></v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn color="red darken-1" text @click="confirm = false">
                                    Renunta
                                </v-btn>

                                <v-btn color="green darken-1" text @click="deleteUser">
                                    De acord
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
            </div>
            <!--end::Body-->
        </form>
        <!--end::Form-->
    </div>
    <!--end::Card-->
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "UsersEditProfileCard",
    props: ["currentUser"],
    components: {},
    data() {
        return {
            confirm: false,
            form: {
                firstName: this.currentUser.firstName,
                lastName: this.currentUser.lastName,
                password: "",
                confirmPassword: "",
            },
        };
    },
    mounted() {},
    computed: {},
    methods: {
        deleteUser(event) {
            event.preventDefault();
            var userId = this.currentUser.id;
            var data = this.currentUser;
            this.$store
                .dispatch("users/deleteUser", { userId, data })
                .then((response) => {
                    if (response.status == "success") {
                        this.$router.push({
                            name: "UsersList",
                        });
                    }
                    // console.log("response", response);
                });
        },
        onSubmit(event) {
            event.preventDefault();
            let userId = this.currentUser.id;
            let data = this.form;
            if (data.password != "" && data.password == data.confirmPassword) {
                this.$store.dispatch("users/editPass", { userId, data });
                this.inputChanged = false;
            }
        },
        onReset() {
            this.form = {
                firstName: this.currentUser.firstName,
                lastName: this.currentUser.lastName,
                password: "",
                confirmPassword: "",
            };
        },
    },
    created() {},
};
</script>
